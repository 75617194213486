<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="User requests">

                <b-button v-if="$hasPermission($permissions.AddUserRequests)" variant="primary" @click="newRequestModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">New</span>
                </b-button>

                <b-form-group class="float-right">
                    <b-form-checkbox v-model="showClosedRequests" switch>Show closed requests</b-form-checkbox>
                </b-form-group>

                <basic-table :columns="columns" :data="userRequests" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="closeRequest(props.row.id)" v-if="props.row.request_status === 0 && $hasPermission($permissions.CloseUserRequests)" class="mr-1">
                            <feather-icon icon="StopCircleIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemoveUserRequests)" variant="danger" @click="removeRequest(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-badge v-if="props.row.request_status === 0" variant="light-warning">
                            Open
                        </b-badge>
                        <b-badge v-else variant="light-primary">
                            Closed
                        </b-badge>
                    </span>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Add request" v-model="newRequestModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Request</label>
                    <b-form-textarea v-model="addObject.request"/>
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="newRequestModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addRequest">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BOverlay, BFormTextarea, BBadge, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'

    export default {
        components: {
            BCard,
            BOverlay,
            BBadge,
            BButton,
            BasicTable,
            BFormGroup,
            BFormTextarea,
            BFormCheckbox
        },
        props: {
            outletId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                dataLoaded: false,
                userRequestsRaw: [],

                showClosedRequests: false,

                columns: [
                    {
                        label: 'Timestamp',
                        displayType: 0,
                        field: 'timestamp',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Status',
                        displayType: 2,
                        field: 'status',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Username',
                        displayType: 0,
                        field: 'username',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'user_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Last name',
                        displayType: 0,
                        field: 'user_last_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Request',
                        displayType: 0,
                        field: 'request',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                newRequestModalActive: false,
                addObject: {
                    request: ''
                }
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/outlet/${this.outletId}/user_request`)
                loadPromise.then(function(response) {
                    thisIns.userRequestsRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addRequest() {
                const thisIns = this
                const addPromise = this.$http.post(`/api/management/v1/outlet/${this.outletId}/user_request`, this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Request added')
                    thisIns.newRequestModalActive = false
                    thisIns.addObject.request = ''
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            closeRequest(id) {
                const thisIns = this
                const closePromise = this.$http.put(`/api/management/v1/user_request/${id}/close`)
                closePromise.then(function() {
                    thisIns.$printSuccess('Request closed')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            async removeRequest(id) {

                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this request??')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/user_request/${id}`)
                    this.$printSuccess('Request removed')                  
                    this.editEnabled = false

                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                    
                } finally {
                    this.loadData()
                }

            }
        },
        computed: {
            userRequests() {
                return this.userRequestsRaw.filter((request) => this.showClosedRequests || request.request_status === 0).map((request, index) => {
                    return {
                        index,
                        id: request.id,
                        timestamp: this.moment(request.timestamp).format('YYYY/MM/DD HH:mm'),
                        request_status: request.request_status,
                        status: (request.request_status === 0) ? 'Open' : 'Closed',
                        username: request.username,
                        user_name: request.user_name,
                        user_last_name: request.user_last_name,
                        request: request.request
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>