<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Promotional items">
                <basic-table :columns="columns" :data="promotionalItems" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="success" @click="$router.push('/promotional_item/' + props.row.id)" class="mr-1">
                            <feather-icon icon="EyeIcon"/>
                        </b-button>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'

    export default {
        components: {
            BCard,
            BOverlay,
            BasicTable,
            BButton
        },
        props: {
            outletId: {
                type: String,
                required:true
            }
        },
        data() {
            return {
                dataLoaded: false,
                promotionalItemsRaw: [],

                columns: [
                    {
                        label: 'Serial number',
                        displayType: 0,
                        field: 'serial_number',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Type',
                        displayType: 0,
                        field: 'promotional_item_type',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/outlet/${this.outletId}/promotional_item`)
                loadPromise.then(function(response) {
                    thisIns.promotionalItemsRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            }
        },
        computed: {
            promotionalItems() {
                return this.promotionalItemsRaw.map((item, index) => {
                    return {
                        index,
                        id: item.id,
                        serial_number: item.serial_number,
                        promotional_item_type: (item.promotional_item_type.length > 0) ? item.promotional_item_type : 'Unknown'
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>